.gradientSectionOne {
    border-radius: 12px;
    background: linear-gradient(135deg, #DAD7FF 10%, #FFC1EE 80%);
    max-width: 100vw;
    display: inline-flex;

    flex-direction: column;
    
    width: 100%;
    padding-top: 6rem;
    @media (min-width: 600px) {
        min-height: 100vh;
        padding-top: 8rem;
        min-height: initial;
        height: auto;
    }

    position: relative;
    z-index: 10;

    .heroContent {
        padding: 32px 64px;

        img {
            border-radius: 12px;
            box-shadow: 0px 11.37015px 25.10909px 0px rgba(0, 0, 0, 0.10), 0px 45.95436px 45.95436px 0px rgba(0, 0, 0, 0.09), 0px 103.27888px 62.06208px 0px rgba(0, 0, 0, 0.05), 0px 183.34369px 73.43223px 0px rgba(0, 0, 0, 0.01), 0px 286.62259px 80.06482px 0px rgba(0, 0, 0, 0.00);
        }
    }

    @media (max-width: 768px) {
        .heroContent {
            padding: 8px 24px 24px 24px;
        }
        .heroCheckInImage {
            width: 100%;
        }
    }
    @media (min-width: 1920px) {
        padding-top: 6rem;
        min-height: initial;
        height: auto;
    }
}

.heroOverline {
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    background: #FFF;
    box-shadow: 0px 2.03796px 3.62304px 0px rgba(0, 0, 0, 0.08), 0px 5.6347px 10.01724px 0px rgba(0, 0, 0, 0.11), 0px 13.56618px 24.11766px 0px rgba(0, 0, 0, 0.14), 0px 45px 80px 0px rgba(0, 0, 0, 0.22);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    .divider {
        background: #D9D9D9;
        width: 1px;
        height: 20px;
        display: block;
    }
}

.headerBig {
    color: #151314;
    text-align: center;
    font-family: 'Gustavo', 'Roobert', sans-serif;
    font-size: 47.438px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.4px;
}

.graySectionTwo {
    margin-top: 16px;
    z-index: 1;
    border-radius: 12px;
    background: #F4F4F4;
    display: inline-flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    width: 100%;
    
    position: relative;
    @media (max-width: 500px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @media (max-width: 768px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @media (min-width: 768px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @media (max-width: 1440px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @media (min-width: 1441px) {
        padding: 64px;
    }
}

.testimonialOne {
    margin-left: auto;
    margin-top: -4rem;

    display: flex;
    width: 100%;
    max-width: 472.31px;
    padding: 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    border-radius: 9px;
    background: #774AF4;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.03), 0px 24px 32px -8px rgba(0, 0, 0, 0.07);
    position: relative;
    z-index: 20;

    .quote {
        color: #FFF;

        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.6px;
    }

    .author {
        color: #E1D7FF;

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
    }
    @media (max-width: 768px) {
        margin-top: 4rem;
    }
}

.gradientSectionTwo {
    border-radius: 12px;
    margin-top: -6.75rem;
    background: linear-gradient(180deg, #FFFEFF 0%, #EDE9FF 100%);
    background: linear-gradient(225deg, #FFDEC1 0%, #FBD7FF 90%);
    display: inline-flex;

    min-height: 100vh;
    flex-direction: column;

    width: 100%;
    padding-top: 4rem;
    position: relative;
    z-index: 10;

    .heroContent {
        padding: 32px 64px;

        img {
            border-radius: 12px;
        }
    }
    @media (max-width: 768px) {
        .heroContent {
        padding: 32px 24px;
        }
    }
    @media (min-width: 1500px) {
        padding-top: 0rem;
        min-height: initial;
    }
}
